import { useQuery } from "@tanstack/react-query"
import { ExtendedWalkReport, WalkReport } from "../../Interfaces"
import { useContext, useEffect, useState } from "react"
import UiContext from "../../context/uiProvider"
import { LARGE_DESKTOP, LARGE_TABLET, TABLET } from "../../utils/screenSize"
import { walkService } from "../../services/walkService"
import Table from "./common/Table"


export default function LocationWalkReportsTable({ locationId }: { locationId: number }) {
  const { deviceView } = useContext(UiContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10) // can be changed to a user setting later
  const [totalWalkReports, setTotalWalkReports] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>("")
  
  const { data, isLoading } = useQuery<{ walkReports: WalkReport[]; totalCount: number }>(["walkReports", "locationId" + locationId, currentPage, limit, searchTerm], () => walkService.getSomeReportsByLocation(locationId, limit, currentPage - 1, searchTerm), {
    cacheTime: 5000,
    suspense: true,
  })

  const [filteredData, setFilteredData] = useState<ExtendedWalkReport[] | null | undefined>(null)

  useEffect(() => {
    // set viewable users for current user. This can be used later to filter users by organization or location w/out making another api call
    if (data) {
      // flattens some of the nested data for easier access
      setTotalWalkReports(data.totalCount)
      const flattenedData: ExtendedWalkReport[] = data.walkReports.map((walkReport: WalkReport) => {
        return {
          ...walkReport,
          createdAt: new Date(walkReport.createdAt).toLocaleString(),
          userName: walkReport.user.firstName + " " + walkReport.user.lastName,
          walkName: walkReport.walk.name,
          isWalkNotes: walkReport.walkNote?.length ? "Yes" : "No",
          isMarkerImages: walkReport.markerImage?.length ? "Yes" : "No",
        }
      })
      setFilteredData(flattenedData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    // any the user changes the limit per page, reset the current page to 1
    setCurrentPage(1)
  }, [limit])

  const headers = [
    { label: "Completed", key: "createdAt", to: `walk-report`},
    { label: "Name", key: "userName", to: `walk-report`},
    { label: "Walk", key: "walkName", to: `walk-report`},
  ]
  // Add two more columns on tablets and desktops
  if (deviceView >= TABLET) {
    headers.unshift({ label: "Id", key: "id", to: `walk-report`})
  }
  // Add three additional columns on desktops
  if (deviceView >= LARGE_TABLET) {
    // future implementation
  }

  // Add additional column on larger screen
  if (deviceView >= LARGE_DESKTOP) {
    headers.push({ label: "Notes", key: "isWalkNotes", to: `walk-report`})
    headers.push({ label: "Images", key: "isMarkerImages", to: `walk-report`})
  }

  function handleSearch(query: string) {
    setCurrentPage(1)
    setSearchTerm(query)
  }

  if (!filteredData || isLoading) {

    return null
  }

  return (
    <Table data={filteredData} title={"Walk Reports"} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} setLimit={setLimit} handleSearch={handleSearch} headers={headers} totalCount={totalWalkReports} setTotalCount={setTotalWalkReports} />
  )
}