import { useState } from "react"
import { confirmPasswordFields } from "../../constants/formFields"
import FormAction from "../forms/FormAction"
import Input from "../forms/Input"
import { FieldState } from "../../Interfaces"
import { validatePasswords } from "../../utils/helper"
import { authService } from "../../services/authService"
import { useNavigate, useParams } from "react-router-dom"

const fields = confirmPasswordFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.id] = ""))

export default function ConfirmNewPassword() {
  const [newPassword, setNewPassword] = useState(fieldsState)
  const navigate = useNavigate()
  const token = useParams().token
  const handleChange = (e: { target: { id: any; value: any } }) => {
    setNewPassword({ ...newPassword, [e.target.id]: e.target.value })
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const valid = validatePasswords(newPassword.password, newPassword.confirmPassword)
    if(!valid) {
      return alert("Passwords do not match")
    } else {
      createNewPassword(newPassword.password)
    }
  }

  const createNewPassword = async (password: string) => {
    if(token){
      try {
        const response = await authService.resetPassword(token, password)
        if(response) {
          alert("Password reset successful. Return to login page to login.")
          navigate("/login") 
        }    
      } catch (error) {
        alert("Password reset failed. Please try again later.")
        navigate("/login")
        
      }
    }

  }

  if(!token) return (<h1>Invalid Link</h1>)

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div className="-space-y-px">
        {fields.map((field) => (
          <Input className="bg-gray-50 border-gray-300" key={field.id} handleChange={handleChange} value={newPassword[field.id]} labelText={field.labelText} labelFor={field.labelFor} id={field.id} name={field.name} type={field.type} isRequired={field.isRequired} placeholder={field.placeholder} />
        ))}
      </div>

      <FormAction handleSubmit={handleSubmit} text="Reset" />
    </form>
  )
}
