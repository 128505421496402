import { useEffect, useState } from "react"
import Searchbar from "./Searchbar"
import { Link } from "react-router-dom"
import { sortByAttribute } from "../../../utils/helper"
import { CgSortAz, CgSortZa } from "react-icons/cg"
import ItemsPerPageSelector from "./ItemsPerPageSelector"
import Pagination from "./Pagination"


interface TableProps {
  data: any[]
  title: string
  currentPage: number
  setCurrentPage: (page: number) => void
  limit: number
  setLimit: (limit: number) => void
  handleSearch: (query: string) => void
  headers: { label: string; key: string, to?: string }[]
  totalCount: number
  setTotalCount: (count: number) => void
  to?: string

}

export default function Table({ data, title, currentPage, setCurrentPage, limit, setLimit, handleSearch, headers, totalCount, setTotalCount, to }: TableProps) {
  const [ tableData, setTableData ] = useState<any[] | undefined | null>(null)
  const [acending, setAcending] = useState(true)

  useEffect(() => {
    if (data) {
      setTableData(data)
      setTotalCount(totalCount)
    }
  }, [data])

  useEffect(() => {
    setCurrentPage(1)
  }, [limit])



  function handleSort(e: any) {
    if (!tableData) return
    if (!e.target.parentNode.name) return
    setAcending((prev) => {
      const sortedData = sortByAttribute(tableData, e.target.parentNode.name, !prev) as any[]
      setTableData(sortedData)
      return !prev
    })
  }

  function handlePageChange(pageNumber: number) {
    setCurrentPage(pageNumber)
  }

  return (
    <div className="w-[inherit]">
      <div className="flex align-middle p-2 max-w-full justify-between">
        <div className="flex align-middle p-2 max-w-full">
          <h2 className="pr-2">{title}</h2>
          <Searchbar handleSearch={handleSearch} />
        </div>
        <div className="flex items-end">
          <ItemsPerPageSelector itemsPerPage={limit} setItemsPerPage={setLimit} />
          <Pagination total={totalCount} itemsPerPage={limit} currentPage={currentPage} onPageChange={handlePageChange} />
        </div>
      </div>
      <div className="md:rounded-lg sm:overflow-auto shadow-md max-w-full">
        <table className="bg-white w-full min-w-full text-start sm:auto">
          <thead>
            <tr className="p-4" onClick={handleSort}>
              {headers.map((header, index) => (
                <th key={header.key + index} className={`px-4 py-2 bg-primary-dark text-white text-start`}>
                  {acending ? (
                    <button name={header.key} className="pl-2">
                      <span className="flex items-center">
                        {header.label}
                        <CgSortAz name={header.key} />
                      </span>
                    </button>
                  ) : (
                    <button name={header.key} className="pl-2">
                      <span className="flex items-center">
                        {header.label}
                        <CgSortZa name={header.key} />
                      </span>
                    </button>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="[&>*:nth-child(odd)]:bg-secondary-extra-light pb-2 ">
            {tableData?.map(( item: { [key: string]: any } | undefined) => (
              <tr key={item?.id + 'tr'}>
                {headers.map((header) => (
                  <td key={header.key + 1} className={`px-4 py-2 text-ellipsis overflow-hidden max-w-[150px] whitespace-nowrap`}>
                    <Link to={header.to && item?.id ? `${header.to}/${item.id}` : ''} state={{ currentItem: item }}>
                      {item && item[header.key]}
                    </Link>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
