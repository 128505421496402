import { useContext, useState } from "react"
import { loginFields } from "../../constants/formFields"
import FormAction from "../forms/FormAction"
import FormExtra from "../forms/FormExtra"
import Input from "../forms/Input"
import { Credentials, FieldState, UserAuth } from "../../Interfaces"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../context/authProvider"
import { authService } from "../../services/authService"

const fields = loginFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.name] = ""))

export default function Login() {
  const [loginFormState, setLoginFormState] = useState(fieldsState)
  const { setAuth } = useContext(AuthContext) as unknown as { auth: UserAuth; setAuth: (auth: UserAuth) => void }
  const navigate = useNavigate()

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setLoginFormState({ ...loginFormState, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    //NATE: ask about this process
    localStorage.removeItem("token")
    const credentials = loginFormState as unknown as Credentials
    try {
      const { accessToken, user } = await authService.getAuthFromCredentials(credentials)
      if (accessToken && user) {
        setAuth({ token: accessToken, user: user })
        // get user info...
        navigate("/")
      }
    } catch (err) {
      alert(err)
    }
  }

  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div className="-space-y-px">
        {fields.map((field) => (
          <Input
            className="bg-gray-50 border-gray-300"
            key={field.id}
            handleChange={handleChange}
            value={loginFormState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            autoComplete={field.autoComplete}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
      </div>

      <FormExtra />
      <FormAction handleSubmit={handleSubmit} text="Login" />
    </form>
  )
}
