import { useQuery } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import UiContext from "../../context/uiProvider"
import { DESKTOP, TABLET } from "../../utils/screenSize"
import useUser from "../../hooks/useUser"
import { Organization } from "../../Interfaces"
import { organizationService } from "../../services/organizationService"
import Table from "./common/Table"


export default function OrganizationsTable({ userProfileId = undefined }: { userProfileId?: number}) {
  const { id } = useUser()
  const { deviceView } = useContext(UiContext)
  const userId = userProfileId || id
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(5) // can be changed to a user setting later
  const [totalOrganizations, setTotalOrganizations] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>("")

  const { data, isLoading } = useQuery<{organizations:Partial<Organization[]>, totalCount:number}>(["organizations", "userId" + userId, currentPage, limit, searchTerm], () => organizationService.getSomeByUser(userId, limit, currentPage - 1, searchTerm), { cacheTime: 5000, suspense: true })

  const [filteredData, setFilteredData] = useState<Partial<Organization[]> | undefined | null>(null)

  useEffect(() => {
    if(!data) return
    setFilteredData(data?.organizations)
    setTotalOrganizations(data?.totalCount)
  }, [data])

  const headers: { label: string, key: string, to?: string}[] = [
    { label: "Id", key: "id", to: "/organizations"},
    { label: "Name", key: "name", to: "/organizations"},
  ]

  // Add two more columns on tablets and desktops
  if (deviceView >= TABLET) {
    // headers.push({ label: "Website", key: "website" }, { label: "Email", key: "email" }, { label: "Phone", key: "phone"})
    headers.push({ label: "Management Company", key: "managementCompanyName" }, { label: "Locations", key: "totalLocations" })
  }
  // Add two additional columns on desktops
  if (deviceView >= DESKTOP) {
    headers.push({ label: "Employees", key: "totalEmployees" })
  }

  function handleSearch(query: string) {
    setCurrentPage(1)
    setSearchTerm(query)
  }


  if( !filteredData || isLoading ) return null

  return (
    <Table data={filteredData} title={"Areas"} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} setLimit={setLimit} handleSearch={handleSearch} headers={headers} totalCount={totalOrganizations} setTotalCount={setTotalOrganizations} />
  )
}