import { Link } from "react-router-dom";
import { authService } from "../../services/authService";
import { FaSignOutAlt } from "react-icons/fa";
import UiContext from "../../context/uiProvider";
import { useContext } from "react";
import AuthContext from "../../context/authProvider";
import { SMALL_TABLET } from "../../utils/screenSize";
import { useQueryClient } from "@tanstack/react-query";

export default function Logout() {
  const { setAuth } = useContext<AuthContext>(AuthContext)
  const { deviceView } = useContext(UiContext)
  const queryClient = useQueryClient()

  function handleLogout() {
    authService.logout()
    queryClient.clear()
    setAuth!({ token:'', user: {id:0}})
  }

  return (
    <Link to={'/login'} className="text-xl font-bold border-none" onClick={handleLogout}>
      {deviceView < SMALL_TABLET ? <FaSignOutAlt /> : <div className="flex justify-end items-center"><FaSignOutAlt className="mr-2"/>Logout</div>}
     </Link>
  )
}