import { useEffect, useRef, useState } from "react"
import { FaSearch } from "react-icons/fa"

interface SearchbarProps {
  handleSearch: (query: string) => void
  className?: string
}

export default function Searchbar({ handleSearch, className }: SearchbarProps) {
  const [value, setValue] = useState("")
  const [open, setOpen] = useState(false)

  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value)
  }

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleSearch(value)
    }
  }

  return (
    <div className="flex">
      {!open ? (
        <FaSearch className="text-gray-600 self-center" onClick={() => setOpen(true)} />
      ) : (
        <input
          className={"sm:text-sm rounded-md appearance-none relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:bg-secondary-extra-light focus:border-purple-500 focus:z-10 focus:w-64" + " " + className}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Search"
          onBlur={() => setOpen(false)}
        ></input>
      )}
    </div>
  )
}
