
interface PopupModalProps {
  isOpen: boolean
  children: React.ReactNode
  title?: string
  message?: string
  onCancel: (e: { preventDefault: () => void }) => void
  className?: string
}

export default function PopupModal({ isOpen, children, title = "", message = "", onCancel, className = "" }: PopupModalProps) {
  if (!isOpen) return null

  return (
    <div className={`fixed z-50 top-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50`}>
      <div className={`rounded w-4/5 flex flex-col ${className} max-w-fit`}>
        {title && <h2 className="text-xl font-bold mb-4 ">{title}</h2>}
        {message && <p className="mb-4">{message}</p>}
        <div className="opacity-75 w-full h-full"></div>
        <div className=" opacity-100 flex flex-col items-center rounded  p-6">
          {children}
          <div className="flex justify-end space-x-2 w-full">
            <button onClick={onCancel} className="px-4 mx-4 mt-2 py-2 bg-gray-200 text-gray-700 rounded hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
