import { useState } from "react"
import Select, { MultiValue, SingleValue } from "react-select"

interface CustomSelectProps {
  list: any[]
  className?: string
  isMulti?: boolean
  placeholder?: string
  handleSelection?: Function
  required?: boolean
  styles?: any
  defaultValues?: any[]
  sortBy?: "asc" | "desc" | null
}

export default function CustomSelect({ list, className, isMulti = false, placeholder = "Select...", handleSelection, required = false, styles, defaultValues, sortBy = null }: CustomSelectProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<unknown>>()
  let newList = list
  if(sortBy){
    if(sortBy === "asc") newList = list ? list.sort((a: { [key: string]: any }, b: { [key: string]: any }) => a.name.localeCompare(b.name)) : []
    if(sortBy === "desc") newList = list ? list.sort((a: { [key: string]: any }, b: { [key: string]: any }) => b.name.localeCompare(a.name)) : []
  }
  const labeledList = newList?.map((item: { [key: string]: any }, index: number) => {
    return { value: index, label: item.name, ...item }
  })

  const defaultSelectedValues = defaultValues
    ? labeledList.filter((item: { [key: string]: any }, index: number) => {
        if (defaultValues.includes(item.name) || defaultValues.includes(item.id)) {
          return { value: index, label: item.name, ...item }
        }
        return null
      })
    : []
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: styles?.background || provided.backgroundColor,
      borderColor: state.isFocused ? "#c9a7f0" : provided.borderColor,
      borderWidth: "1px",
      boxShadow: state.isFocused ? "0 0 0 .2px #c9a7f0" : null, // to override the default blue box-shadow
      "&:hover": {
        borderColor: state.isFocused ? "#c9a7f0" : provided.borderColor,
        borderWidth: "1px",
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: "black",
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#006cb0" || provided.backgroundColor,
      color: "white",
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#8e44ad",
      color: "white",
    }),
  }

  const setHandle = (e: SingleValue<unknown> | MultiValue<unknown>) => {
    // e = selected options full object
    if (handleSelection) handleSelection(e)
    const items = Array.isArray(e) ? e.map((option) => option.label) : []
    setSelectedOptions(items)
  }

  return (
    <div className={`w-full ` + className}>
      <Select options={labeledList} onChange={setHandle} placeholder={placeholder} isMulti={isMulti} styles={customStyles} required={required} defaultValue={defaultSelectedValues} />
    </div>
  )
}
