import { Organization } from "../../../../Interfaces"
import { useNavigate, useParams } from "react-router-dom"
import { FaPlus } from "react-icons/fa6"
import OrganizationUsersTable from "../../../../components/tables/OrganizationUsersTable"
import OrganizationLocationsTable from "../../../../components/tables/OrganizationLocationsTable"
import { useErrorBoundary } from "react-error-boundary"
import { useState } from "react"
import { isEmpty } from "../../../../utils/helper"
import useDevice from "../../../../hooks/useDevice"
import { SMALL_TABLET } from "../../../../utils/screenSize"
import ConfirmationModal from "../../../../components/modals/ConfirmationModal"
import { organizationService } from "../../../../services/organizationService"
import SaveButtonWithStatus from "../../../../components/general/SaveButtonWithStatus"
import ImageDragDrop from "../../../../components/forms/ImageDragDrop"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import OrganizationLogo from "../../../../components/images/OrganizationLogo"
import DynamicInput from "../../../../components/forms/DynamicInput"

export default function UpdateOrganization() {
  const deviceView = useDevice()
  const navigate = useNavigate()
  const { showBoundary } = useErrorBoundary()
  const { id: orgId } = useParams()
  const queryClient = useQueryClient()
  const { data: organization } = useQuery(["organization", "organizationId" + orgId], () => organizationService.getOne(Number(orgId)))
  
  const thisOrganization = organization

  if (isEmpty(thisOrganization)) {
    showBoundary("User not found")
    throw new Error("User not found")
  }
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [preview, setPreview] = useState("")
  const [activateUpload, setActivateUpload] = useState<boolean>(false)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)
  const [deleteStatus, setDeleteStatus] = useState<string | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [organizationProfile, setOrganizationProfile] = useState<Organization>(thisOrganization)

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setOrganizationProfile({ ...organizationProfile, [e.target.name]: e.target.value })
  }

  const handleUpdateOrganization = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    let organizationInfoToUpdate: Partial<Organization> = { id: organizationProfile.id, name: organizationProfile.name }
    setSaveStatus("Saving...")
    let updatedOrganization: Partial<Organization> | null = null
    try {
      updatedOrganization = await organizationService.update(organizationInfoToUpdate, imageFile)
    } catch (error) {
      showBoundary(error)
    }
    if (updatedOrganization) {
      setSaveStatus("Saved")
      setTimeout(() => {
        setSaveStatus(null)
        queryClient.invalidateQueries()
        queryClient.refetchQueries()
        navigate(`/organizations/${thisOrganization.id}`, { state: { currentItem: updatedOrganization } })
      }, 1000)
    } else {
      setSaveStatus("Error")
      setTimeout(() => {
        setSaveStatus(null)
      }, 1500)
    }
  }

  const toggleDeleteModal = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteOrganization = async () => {
    try {
      setDeleteStatus("Deleting...")
      const deletedUser = await organizationService.delete(thisOrganization.id)

      if (deletedUser) {
        setDeleteStatus("Deleted")
        setTimeout(() => {
          setDeleteStatus(null)
          toggleDeleteModal({ preventDefault: () => {} })
          navigate("/")
        }, 1000)
      } else {
        setDeleteStatus("Error")
        setTimeout(() => {
          setDeleteStatus(null)
        }, 1500)
      }
    } catch (error) {
      showBoundary(error)
    }
  }

  const handleNewImage = (file: File | null) => {
    setPreview(URL.createObjectURL(file!))
    setImageFile(file)
  }

  return (
    <div className="w-full flex flex-col items-center">
      <ConfirmationModal isOpen={showDeleteModal} title={"Delete Profile?"} message={"All data associated with this organization will be deleted forever!"} onConfirm={handleDeleteOrganization} onCancel={toggleDeleteModal} saveStatus={deleteStatus} />
      <div className="w-full md:max-w-3xl flex flex-wrap bg-secondary-extra-light p-2 sm:rounded-lg shadow-lg h-min ">
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start w-full md:w-56 lg:w-64 m-2 ">
          <div className="relative w-fit m-auto hover:cursor-pointer" onClick={() => setActivateUpload(!activateUpload)}>
            <ImageDragDrop setImageState={handleNewImage} className="hidden" activateUpload={activateUpload} />
            {preview ? <img src={preview} alt="User Profile" className="w-44 h-44 lg:w-44 lg:h-44 rounded-full mx-auto border-4 border-secondary-extra-light" /> : <OrganizationLogo company={organization} />}

            {!organization.logoName && (
              <div className=" absolute top-10 right-10">
                <FaPlus />
              </div>
            )}
          </div>
          <h2 className="text-xl mt-4 text-center">{`${organization.name}`}</h2>
          <p className="text-center">ID: {organization.id}</p>
        </div>
        <div className="bg-primary-dark text-white p-4 rounded-lg justify-self-start m-2 max-w-full flex-1 whitespace-nowrap relative">
          {organization.createdAt && (
            <p>
              <strong>Created: </strong>
              {new Date(organization.createdAt).toLocaleDateString()}
            </p>
          )}
          <form action="">
            <div className="absolute top-3 right-3">
              <div className="w-100 flex flex-col justify-end">
                <SaveButtonWithStatus saveStatus={saveStatus} handleSave={handleUpdateOrganization} text="Save" />
                <button className="bg-primary block mt-2 text-white p-2 rounded-lg hover:shadow-right-lg transform active:translate-y-1 active:scale-[.99]  active:shadow-none font-medium" onClick={toggleDeleteModal}>
                  Delete
                </button>
              </div>
            </div>
            <p>
              <strong>Name: </strong>
              <DynamicInput
                className="m-2 rounded-md bg-secondary-extra-light text-black px-2 min-w-[100px]"
                type="text"
                value={organizationProfile.name}
                name="name"
                onChange={handleChange}
                
              />
            </p>
          </form>
        </div>
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <OrganizationUsersTable organizationId={organization.id} />
      </div>
      <div className="w-full flex flex-wrap sm:rounded-lg mt-4 pb-4">
        <OrganizationLocationsTable organizationId={organization.id} />
      </div>
    </div>
  )
}
