import { useState } from "react"
import { resetPasswordFields } from "../../constants/formFields"
import FormAction from "../forms/FormAction"
import Input from "../forms/Input"
import { FieldState } from "../../Interfaces"
import { authService } from "../../services/authService"
import { useNavigate } from "react-router-dom"

const fields = resetPasswordFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.id] = ""))

export default function ResetPassword() {
  const [loginState, setLoginState] = useState(fieldsState)
  const navigate = useNavigate()

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value })
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    sendResetPasswordLink().then((userExists: boolean) => {
      if (userExists) {
        //re-direct to email sent page
        navigate("emailSent")
      }
    })
  }

  //Handle Login API Integration here
  const sendResetPasswordLink = async (): Promise<boolean> => {
    //check if account exists in db with prisma
    try {
      const response: boolean = await authService.forgotPassword(loginState.email)
      return response
    } catch (error) {
      
      alert("Account does not exist")
      return false
    }
  }
  
  return (
    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
      <div className="-space-y-px">
        {fields.map((field) => (
          <Input className="bg-gray-50 border-gray-300" key={field.id} handleChange={handleChange} value={loginState[field.id]} labelText={field.labelText} labelFor={field.labelFor} id={field.id} name={field.name} type={field.type} isRequired={field.isRequired} placeholder={field.placeholder} />
        ))}
      </div>
      <FormAction handleSubmit={handleSubmit} text="Reset" />
    </form>
  )
}
