import { useQuery } from "@tanstack/react-query"
import { userService } from "../../services/userService"
import { User } from "../../Interfaces"
import { useContext, useEffect, useState } from "react"
import { roles } from "../../utils/roles"
import UiContext from "../../context/uiProvider"
import { LARGE_DESKTOP, LARGE_TABLET, TABLET } from "../../utils/screenSize"
import Table from "./common/Table"
import useUser from "../../hooks/useUser"

export default function LocationUsersTable({ locationId }: { locationId: number }) {
  const { id } = useUser()
  const { deviceView } = useContext(UiContext)
  const userId = id
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10) // can be changed to a user setting later
  const [totalUsers, setTotalUsers] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>("")

  const { data } = useQuery<{users: User[], totalCount: number}>(["users", "locationId" + locationId], () => userService.getSomeByLocation(locationId, limit, currentPage, searchTerm), {
    select: (data) => {
      return {
        users: data.users.map((user) => {
          user.roleName = roles[user.roleId || 20]
          user.userToLocation = user.userToLocation || []
          return user
        }),
        totalCount: data.totalCount
      };
    },
    staleTime: 10000,
  })

  const [filteredData, setFilteredData] = useState(data?.users)


  useEffect(() => {
    setFilteredData(data?.users)
    setTotalUsers(data?.totalCount || 0)
  }, [data])

  const headers: {
    label: string;
    key: string;
    to?: string;
}[] = [
    { label: "First Name", key: "firstName", to: `/users`},
    { label: "Last Name", key: "lastName", to: `/users`},
  ]
  // Add two more columns on tablets and desktops
  if (deviceView >= TABLET) {
    headers.unshift({ label: "Id", key: "id", to: `/users`})
    headers.push({ label: "Role", key: "roleName" })
  }
  // Add three additional columns on desktops
  if (deviceView >= LARGE_TABLET) {
    headers.push({ label: "Phone", key: "phone" }, { label: "Email", key: "email" }, { label: "Primary Location", key: "defaultLocationId" })
  }

  // Add additional column on larger screen
  if (deviceView >= LARGE_DESKTOP) {
    // headers.push({ label: "Organizations", key: "userToOrganization" })
  }

  function handleSearch(query: string) {
    setCurrentPage(1)
    setSearchTerm(query)
  }

  if (!filteredData) return null

  return (
    <Table data={filteredData} title={"Location Employees"} currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} setLimit={setLimit} handleSearch={handleSearch} headers={headers} totalCount={totalUsers} setTotalCount={setTotalUsers} />
  )
}
