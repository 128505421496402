import { useState, useEffect } from 'react';
import { ImageCheckerResult } from '../Interfaces';

const useImageChecker = (src: string): ImageCheckerResult => {
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isImageError, setImageError] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();

    // Define event listeners for the Image object
    image.onload = () => {
      setImageLoaded(true);
      setImageError(false);
    };
    image.onerror = () => {
      setImageLoaded(false);
      setImageError(true);
    };

    // Start loading the image
    image.src = src;

    // Cleanup: Remove event listeners
    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [src]); // Re-run the effect if the 'src' changes

  return { isImageLoaded, isImageError };
};

export default useImageChecker;
