import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Location, ManagementCompany, Organization } from "../../../../Interfaces"
import Map from "../../../../components/maps/Map"
import { FormEvent, useRef, useState } from "react"
import { locationService } from "../../../../services/locationService"
import SaveButtonWithStatus from "../../../../components/general/SaveButtonWithStatus"
import ConfirmationModal from "../../../../components/modals/ConfirmationModal"
import { GiCrosshair } from "react-icons/gi"
import { useQuery } from "@tanstack/react-query"
import { managementCompanyService } from "../../../../services/managementCompanyService"
import { organizationService } from "../../../../services/organizationService"
import useUser from "../../../../hooks/useUser"
import { COMPANY_MANAGER } from "../../../../utils/roles"
import CustomSelect from "../../../../components/forms/CustomSelect"
import { MultiValue } from "react-select"

export default function UpdateLocation() {
  const { roleId } = useUser()
  const location = useLocation()
  const { id } = useParams()
  const locationId = Number(id)
  const { currentLocation } = location.state as { currentLocation: Location }
  const [zoom, setZoom] = useState<number>(currentLocation.zoomLevel ? currentLocation.zoomLevel : 18)
  const [savingStatus, setSavingStatus] = useState<null | string>(null)
  const [deleteStatus, setDeleteStatus] = useState<null | string>(null)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>(currentLocation.organizationId || 0)

  const navigate = useNavigate()
  const coordinates = useRef<google.maps.LatLngLiteral>({ lat: currentLocation.latitude, lng: currentLocation.longitude })

  const { data: ManagementCompany } = useQuery(["management-company", locationId], () => managementCompanyService.getByLocation(locationId), {})

  const { data: organizationData } = useQuery(["organizations"], () => organizationService.getAllByManagementCompany(ManagementCompany?.id || 0), {
    enabled: ManagementCompany !== undefined && ManagementCompany.id !== 0,
  })

  const { data: organizationName } = useQuery<string>(["organization-name", locationId], () => locationService.getOrganizationName(locationId), {
    enabled: locationId !== undefined,
  })

  const { data: managementCompanyName } = useQuery<string>(["management-company-name", locationId], () => locationService.getManagementCompanyName(locationId), {
    enabled: locationId !== undefined,
  })

  const handleSaveArea = async () => {
    setSaveStatus("Saving...")
    try {
      const status = await locationService.update({ ...currentLocation, organizationId: selectedOrganizationId })
      if (status) {
        setSaveStatus("Saved!")
        setTimeout(() => {
          setSaveStatus(null)
        }, 1500)
      } else {
        setSaveStatus("Error")
        setTimeout(() => {
          setSaveStatus(null)
        }, 1500)
      }
    } catch (error) {
      // show error button or show error fallback page?
      setSaveStatus("Error")
      setTimeout(() => {
        setSaveStatus(null)
      }, 1500)
      // showBoundary(error)
    }
  }

  const handleSaveZoomAndCenter = async () => {
    setSavingStatus("Saving...")
    try {
      const status = await locationService.update({ ...currentLocation, zoomLevel: zoom })
      if (status) {
        setSavingStatus("Saved!")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      } else {
        setSavingStatus("Error")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      }
    } catch (error) {
      // show error button or show error fallback page?
      setSavingStatus("Error")
      setTimeout(() => {
        setSavingStatus(null)
      }, 1500)
      // showBoundary(error)
    }
  }

  const handleLocationAdjustment = (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
    coordinates.current.lat = Number(latLng.lat)
    coordinates.current.lng = Number(latLng.lng)
    currentLocation.latitude = Number(latLng.lat)
    currentLocation.longitude = Number(latLng.lng)
  }

  const handleDeleteLocation = async () => {
    setDeleteStatus("Deleting...")
    if (!currentLocation.id) return
    try {
      const status = await locationService.delete(currentLocation.id)
      if (status) {
        setSavingStatus("Deleted!")
        setTimeout(() => {
          setSavingStatus(null)
          setShowDeleteConfirmationModal(false)
          navigate("/locations")
        }, 1500)
      } else {
        setSavingStatus("Error")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      }
    } catch (error) {
      // show error button or show error fallback page?
      setSavingStatus("Error")
      setTimeout(() => {
        setSavingStatus(null)
      }, 1500)
      // showBoundary(error)
    }
  }

  const handleOrganizationSelect = (organization: Organization) => {
    setSelectedOrganizationId(organization.id)
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <div className="w-full flex flex-col">
        <section className="bg-secondary-extra-light p-2 md:rounded-lg mb-2 shadow-lg h-full">
          <div className="bg-primary-dark h-full flex flex-col">
            <div className=" text-white rounded-lg p-4 flex justify-between">
              <div>
                <h3 className="">{currentLocation.name}</h3>
                <h4 className="">{currentLocation.address}</h4>
                <h4 className="">{`${currentLocation.city}, ${currentLocation.state} ${currentLocation.zipcode}`}</h4>
              </div>
              <div>
                {managementCompanyName && (
                  <h4>
                    Management Company:<span className="text-base">{" " + managementCompanyName}</span>
                  </h4>
                )}
                {roleId && roleId < COMPANY_MANAGER && (
                  <h4 className="">
                    Area:<span className="text-base">{" " + organizationName}</span>
                  </h4>
                )}
                {roleId && roleId >= COMPANY_MANAGER && (
                  <div>
                    <strong>Area</strong>
                    <div className="flex">
                      <CustomSelect list={organizationData || []} placeholder="Select areas(s)" className="rounded-lg" styles={{ background: "#daf1ff" }} defaultValues={[currentLocation.organizationId] || []} handleSelection={handleOrganizationSelect} required={true} />
                      <SaveButtonWithStatus
                        className="ml-2"
                        saveStatus={saveStatus}
                        handleSave={handleSaveArea}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="flex justify-end p-2">
                  <Link to={`../locations/${currentLocation.id}`} className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light">
                    Walk Reports
                  </Link>
                  <Link to={`../locations/${currentLocation.id}/create-walks`} className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" state={{ currentLocation }}>
                    Setup Walks
                  </Link>
                  <button className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" onClick={() => setShowDeleteConfirmationModal(true)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-grow">
              <div className="shadow-lg  bg-secondary-extra-light w-full flex-grow relative">
                <GiCrosshair className=" text-secondary text-5xl absolute z-10 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2" />
                <Map zoom={zoom} setZoom={setZoom} name={currentLocation.name} coordinates={coordinates.current} handleLocationAdjustment={handleLocationAdjustment} />
                <SaveButtonWithStatus saveStatus={savingStatus} handleSave={handleSaveZoomAndCenter} text="Save Zoom & Center" className="absolute w-50 bottom-1 left-1 shadow-right-lg" />
              </div>
            </div>
          </div>
        </section>
      </div>
      {showDeleteConfirmationModal && (
        <ConfirmationModal isOpen={showDeleteConfirmationModal} title={`Delete ${currentLocation.name}?`} message={`This will delete ${currentLocation.name} and it's walk reports.`} saveStatus={deleteStatus} onConfirm={handleDeleteLocation} onCancel={() => setShowDeleteConfirmationModal(false)} />
      )}
    </div>
  )
}
